import barba from '@barba/core';
import { gsap } from "gsap/all";

import NavMobile from "./nav-mobile";
import Slider from "./slider";
import Header from "./header";
import Reveal from "./reveal";
import Embed from "./embed";
import RaumstylistAccordion from "./accordion";

export default class BarbaJs {

  constructor() {
    const body = document.querySelector('body');
    const headerNavigation = document.querySelector('.header--navigation');
    const navigationLinks = document.querySelectorAll('.navigation--link');


    barba.init({
      timeout: 5000,
      // debug: true,
      // cacheIgnore: true,
      // prefetchIgnore: true,

      // requestError: (trigger, action, url, response) => {
      //   console.log({
      //     trigger: trigger,
      //     action: action,
      //     url: url,
      //     response: response,
      //   })
      // },

      prevent: ({ el }) => el.classList && (el.classList.contains('prevent-barba') || el.classList.contains('active')),

      transitions: [
        {
          name: 'default-transition',

          once(data) {
            setTimeout(() => {
              new Header();
              new NavMobile();
              new Slider('.js--slider');
              new Reveal('.js--reveal');
              new Embed('.js--embed')
              new RaumstylistAccordion('.js--accordion');
            }, 1);

            return gsap.to(body, {
              opacity: 1,
              duration: 1,
              delay: 0.25,
            });
          },

          beforeLeave(data) {
            if (headerNavigation) {
              headerNavigation.classList.add('fade-out');
            }
            return gsap.to(data.current.container, {
              opacity: 0,
              duration: 0.5,
              delay: data.trigger.classList != undefined && data.trigger.classList.contains('navigation--header-link') ? 1 : 0.4,
            });
          },

          afterLeave(data) {
            window.scrollTo({
              top: 0,
              left: 0,
            });

            // update body classes
            body.classList.toggle('is-home', data.next.namespace === 'home');
            body.classList.toggle('not-home', data.next.namespace !== 'home');
          },

          beforeEnter(data) {
            if (headerNavigation) {
              headerNavigation.classList.remove('fade-out');
              navigationLinks.forEach(navigationLink => {
                navigationLink.classList.toggle('is-active', navigationLink.dataset.slug === data.next.namespace);
              });
            }

            data.next.container.style.opacity = 0;
          },

          enter(data) {
            data.current.container.remove();
            // reinit JS
            setTimeout(() => {
              new Header();
              new NavMobile();
              new Slider('.js--slider');
              new Reveal('.js--reveal');
              new Embed('.js--embed')
              new RaumstylistAccordion('.js--accordion');
            }, 1);

          },

          afterEnter(data) {
            return gsap.to(data.next.container, {
              opacity: 1,
              duration: 1,
              delay: 0.5,
            });
          }

        },
      ],

    });
  }

}
