import Cookies from "js-cookie";

export default class Embed {

  constructor(selector) {
    setTimeout(() => {
      this.wrappers = document.querySelectorAll(selector);
      if (!this.wrappers.length) {
        return;
      }

      if (this.didUserAgree()) {
        this.setupAgreed();
      }
      else {
        this.setupDisagreed();
      }
    }, 1);
  }


  setupAgreed() {
    for (let i = 0; i < this.wrappers.length; ++i) {
      const template = this.wrappers[i].querySelector('#embed--template-' + this.wrappers[i].dataset['embedrand']);
      const container = this.wrappers[i].querySelector('#embed--container-' + this.wrappers[i].dataset['embedrand']);
      const footer = this.wrappers[i].querySelector('.embed--footer');
      const contentClone = template.content.cloneNode(true);

      const toggle = this.wrappers[i].querySelector('.embed--toggle-active');
      toggle.addEventListener('click', (event) => {
        event.preventDefault();
        this.userDisagreed();
      });

      container.innerHTML = '';
      container.appendChild(contentClone);
      footer.style.display = 'block';
    }
  }


  setupDisagreed() {
    for (let i = 0; i < this.wrappers.length; ++i) {
      const toggle = this.wrappers[i].querySelector('.embed--toggle-inactive');
      toggle.addEventListener('click', (event) => {
        event.preventDefault();
        Cookies.set('embed-agreed', 'true', { expires: 120, path: '/', domain: '.' + window.location.host, sameSite: 'strict' });
        this.setupAgreed();
      });
    }
  }


  userDisagreed() {
    for (let i = 0; i < this.wrappers.length; ++i) {
      const container = this.wrappers[i].querySelector('#embed--container-' + this.wrappers[i].dataset['embedrand']);
      container.innerHTML = '';
    }

    Cookies.set('embed-agreed', 'false', { expires: 120, path: '/', domain: '.' + window.location.host, sameSite: 'strict' });
    setTimeout(() => {
      window.location.reload();
    }, 1);
  }


  didUserAgree() {
    return Cookies.get('embed-agreed') === 'true';
  }

}
