class ReferencesSlider extends HTMLElement {

  constructor() {
    super();

    this.scrollWrapper = this.querySelector('.scroll-wrapper');
    this.triggerLeft = this.querySelector('.trigger-left');
    this.triggerRight = this.querySelector('.trigger-right');

    this.triggerLeft.addEventListener('click', (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.scrollWrapper.scrollBy({
        left: -1 * this.querySelector('.reference-teaser').clientWidth,
        behavior: 'smooth',
      })
    });

    this.triggerRight.addEventListener('click', (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.scrollWrapper.scrollBy({
        left: this.querySelector('.reference-teaser').clientWidth,
        behavior: 'smooth',
      })
    });
  }

}


// Define the new web component
if ('customElements' in window) {
  customElements.define('references-slider', ReferencesSlider,);
}
