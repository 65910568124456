export default class Header {

  constructor() {
    this.html = document.querySelector('html');
    this.header = document.querySelector('.header');
    // this.logo = document.querySelector('.header-logo');
    // this.logoColors = [
    //   'hover:text-fuchsia-400',
    //   'hover:text-amber-500',
    //   'hover:text-green-400',
    //   'hover:text-red-300',
    //   'hover:text-teal-400',
    //   'hover:text-sky-400',
    //   'hover:text-blue-400',
    //   'hover:text-indigo-500',
    //   'hover:text-yellow-300',
    //   'hover:text-purple-500',
    //   'hover:text-pink-500',
    //   'hover:text-gray-400',
    //   'hover:text-slate-500',
    // ];

    window.addEventListener('scroll', (event) => {
      this.scrollHandler();
    });

    // this.logo.addEventListener('mouseleave', (event) => {
    //   this.logo.classList.forEach(className => {
    //     if (className.startsWith('hover:')) {
    //       this.logo.classList.replace(className, this.logoColors[Math.floor(Math.random() * this.logoColors.length)]);
    //     }
    //   });
    // });
  }

  scrollHandler() {
    this.header.classList.toggle('has-scrolled', this.html.scrollTop > this.header.clientHeight);
  }

}
